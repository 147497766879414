import { FaClock, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';


export default function InfoSection(){
    return(
<div className="container section rounded  py-5 mt-5 bg-white">
  <div className="row text-center align-items-center">
    {/* Open Hours Section */}
    <div className="col-md-4 d-flex flex-column align-items-center py-3">
      <div className="icon-circle bg-light rounded-circle d-flex justify-content-center align-items-center mb-3" style={{ width: '50px', height: '30px' }}>
        <FaClock color="green" size="22px" />
      </div>
      <h6 className="font-weight-bold">Open Hours</h6>
      <ul className="list-unstyled text-muted">
        <li><strong>Mon-Sun:</strong> 11:00 AM - 8:30 PM</li>
        <li><strong>Fri-Sat:</strong> 11:00 AM - 9:00 PM</li>
      </ul>
    </div>

    {/* Address Section */}
    <div className="col-md-4 d-flex flex-column align-items-center border-start border-end py-3">
      <div className="icon-circle bg-light rounded-circle d-flex justify-content-center align-items-center mb-3" style={{ width: '50px', height: '30px' }}>
        <FaMapMarkerAlt color="green" size="22px" />
      </div>
      <h6 className="font-weight-bold mb-2">120 Bass Pro Blvd</h6>
      <h6 className="font-weight-bold mb-0">Denham Springs, LA 70726</h6>
      <small className="text-muted">Find us</small>
    </div>

    {/* Contact Section */}
    <div className="col-md-4 d-flex flex-column align-items-center py-3">
      <div className="icon-circle bg-light rounded-circle d-flex justify-content-center align-items-center mb-3" style={{ width: '50px', height: '30px' }}>
        <FaPhone color="green" size="22px" />
      </div>
      <a href="tel:2252392001" className="text-decoration-none">
        <h6 className="font-weight-bold mb-0">225-239-2001</h6>
      </a>
      <small className="text-muted">Call us</small>
    </div>
  </div>
</div>

    )
}
