import React from 'react';
import Slider from 'react-slick';
import './css/Testimonials.css'
const testimonials = [
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
    name: "Williams Johon",
    location: "Barton, UK",
    profileImage: "https://cdn.prod.website-files.com/65bb586d174022c9cfb50690/65bb586d174022c9cfb506a2_Client%20Image.png",
    dishImage: "https://cdn.prod.website-files.com/65bb586d174022c9cfb50690/65bb586d174022c9cfb506a8_Testimonial%20Image.png"
  },
  {
    text: "Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.",
    name: "Weaver Vanessa",
    location: "Luton, UK",
    profileImage: "https://cdn.prod.website-files.com/65bb586d174022c9cfb50690/65bb586d174022c9cfb506ab_Client%20Image%202.jpg",
    dishImage: "https://cdn.prod.website-files.com/65bb586d174022c9cfb50690/65bb586d174022c9cfb506a9_Dish.jpg"
  }
];

const SimpleSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <div className="simple-slider">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="slide">
            <div className="testimonial">
              <img src={testimonial.dishImage} alt="Dish" className="dish-image" />
              <p>{testimonial.text}</p>
              <div className="profile">
                <img src={testimonial.profileImage} alt={testimonial.name} className="profile-image" />
                <div>
                  <strong>{testimonial.name}</strong>, {testimonial.location}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SimpleSlider;
