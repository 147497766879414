import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './css/OrderHelper.css'
import { useNavigate } from 'react-router-dom';

const flavors = [
  {
    name: 'Garlic Parmesan',
    description: 'Savory garlic, buttery parmesan. This dry rub is simple, potent, and delicious.',
    heatLevel: 1,
    icon: 'https://cdn.bfldr.com/NDQASMJ1/as/m6pkbghwfhc7nfkkwrk79pk/Flavor-LemonPepper?auto=webp&format=png&width=240',
  },
  {
    name: 'BBQ',
    description: 'Classic BBQ flavor with a hint of sweetness.',
    heatLevel: 1,
    icon: 'https://cdn.bfldr.com/NDQASMJ1/as/m6pkbghwfhc7nfkkwrk79pk/Flavor-LemonPepper?auto=webp&format=png&width=240',
  },
  {
    name: 'Hot',
    description: 'Perfect for those who love some heat.',
    heatLevel: 2,
    icon: 'https://cdn.bfldr.com/NDQASMJ1/as/s6vctht7gjcch4msmc9rvt7s/Flavor-MangoHabanero?auto=webp&format=png&width=120',
  },
  {
    name: 'Lemon Pepper',
    description: 'Zesty and fresh with a hint of spice.',
    heatLevel: 1,
    icon: 'https://cdn.bfldr.com/NDQASMJ1/as/m6pkbghwfhc7nfkkwrk79pk/Flavor-LemonPepper?auto=webp&format=png&width=240',
  },
  {
    name: 'Crazy Wings Sauce',
    description: 'Fiery and bold, for the bravest.',
    heatLevel: 3,
    icon: 'https://cdn.bfldr.com/NDQASMJ1/as/vwbxxzpg442rvs2fwngswqj4/Flavor-Atomic?auto=webp&format=png&width=240',
  },
];

const FlavorSection = () => {
  const [heatLevel, setHeatLevel] = useState(1);
  const navigate = useNavigate()

  const handleHeatChange = (event) => {
    setHeatLevel(Number(event.target.value));
  };

  const responsive = {
    largeDesktop: { breakpoint: { max: 4000, min: 1200 }, items: 3 },
    desktop: { breakpoint: { max: 1200, min: 768 }, items: 2 },
    tablet: { breakpoint: { max: 768, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  return (
    <div className="flavor-section text-center text-white">
      <div className="container">
        <h2 className="flavor-title">Find Your Flavor</h2>
        <p className="flavor-subtitle">
          Explore our saucy or dry rub flavors that range from mild to hot, in sweet or savory.
        </p>

        {/* Heat Level Slider */}
        <div className="heat-level-slider my-4">
          <input
            type="range"
            min="1"
            max="3"
            value={heatLevel}
            onChange={handleHeatChange}
            className="slider w-75"
          />
          <div className="heat-level-labels d-flex justify-content-between w-75 mx-auto">
            <span>No Heat</span>
            <span>Some Heat</span>
            <span>All the Heat</span>
          </div>
        </div>

{/* Flavor Carousel */}
<Carousel responsive={responsive} className="flavor-carousel" itemClass="padding-car">
  {flavors.filter((flavor) => flavor.heatLevel === heatLevel).length > 0 ? (
    flavors
      .filter((flavor) => flavor.heatLevel === heatLevel)
      .map((flavor, index) => (
        <div key={index} className="flavor-card card mx-2 p-3">
          <img src={flavor.icon} alt={`${flavor.name} icon`} className="flavor-icon mx-auto" />
          <h3 className="mt-3 text-black">{flavor.name}</h3>
          <p className="flavor-description">{flavor.description}</p>
        </div>
      ))
  ) : (
    <div className="text-center text-white mt-4">
      <p>No flavors available for this heat level. Try adjusting the slider!</p>
    </div>
  )}
</Carousel>



        {/* Explore Menu Button */}
        <button className="btn explore-menu-btn mt-4" onClick={() => navigate('/menu')}>Explore Menu</button>
      </div>
    </div>
  );
};

export default FlavorSection;
