import React, { createContext, useState } from 'react';

const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [restaurantId, setRestaurantId] = useState(20);
  const [changedCart, setChangedCart] = useState(false);
  const [language, setLanguage] = useState('en');
  const [scheduleTime,setScheduleTime] = useState(null)

  return (
    <Context.Provider value={{ restaurantId, setRestaurantId, changedCart, setChangedCart, language, setLanguage, scheduleTime,setScheduleTime }}>
      {children}
    </Context.Provider>
  );
};

export default Context;
