import React from 'react';
import logo from '../asset/Images/logo.png';
import { FaFacebookF, FaTwitter, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
import './css/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo and Social Media */}
        <div className="footer-logo-section">
          <img src={logo} alt="Hongkong Logo" className="footer-logo" />
          <div className="footer-socials">
            <FaFacebookF />
            <FaTwitter />
            <FaYoutube />
            <FaLinkedinIn />
          </div>
        </div>

        {/* Opening Times */}
        <div className="footer-opening-times">
          <h4>Opening Times</h4>
          <ul>
            <li><strong>Mon-Sun:</strong>11:00am-8:30pm</li>
            <li><strong>Fri-Sat:</strong>11:00am-9:00pm</li>

          </ul>
        </div>

        {/* Contact Information */}
        <div className="footer-contact">
          <h4>Contact Us</h4>
          <p>Crazy Wings</p>
          <p>120 Bass Pro Blvd Denham Springs, LA 70726</p>
          <a href='tel:2252392001'>Phone: 225-239-2001</a>
          <p>Powered by Owenmenu</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
