import React from 'react';
import HeroSection from '../components/HeroSection';
import BannerSection from '../components/BannerSection';
import OrderHelper from '../components/OrderHelper';
import EthosSection from '../components/EthosSection';
import Testmionals from '../components/Testimonials'
import Footer from '../components/Footer'
import InfoSection from '../components/InfoSection'
import FlavorSection from '../components/OrderHelper';
const HomePage = ({restaurant}) => {
  const dimSumCat = restaurant?.Categories[17]?.Items


  return (
   <div style={{marginTop:'130px', backgroundColor:'white'}} >
   <HeroSection />
   <InfoSection />
   <BannerSection />
   <FlavorSection />
   <Footer />
   </div>
  );
};

export default HomePage;
