import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../asset/Images/logo.png';
import { FaShoppingBag, FaUser, FaGlobe, FaCar } from 'react-icons/fa';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const SCROLL_UP_THRESHOLD = 1000;

  const handleNavClick = (path) => {
    navigate(path);
  
    // Close the menu if it's open
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('.navbar-collapse');
  
    if (navbarToggler && navbarCollapse.classList.contains('show')) {
      navbarToggler.click(); // Toggles the collapse
    }
  }

  useEffect(() => {
    const categorySection = document.querySelector('.category-menu-wrapper');
    if (categorySection && location.pathname === '/order') {
      categorySection.style.top = isVisible ? '100px' : '0'; // Adjust the top value based on your header height
      categorySection.style.transition = 'top 0.5s';
    }
  }, [isVisible, location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === '/order') {
        const currentScrollY = window.scrollY;
        let distanceScrolled = 0;

        if (currentScrollY === 0) {
          setIsVisible(true);
        } else {
          const isScrollingDown = currentScrollY > lastScrollY;

          if (isScrollingDown) {
            setIsVisible(false);
          } else {
            distanceScrolled += lastScrollY - currentScrollY;
            if (distanceScrolled >= SCROLL_UP_THRESHOLD) {
              setIsVisible(true);
              distanceScrolled = 0;
            }
          }
        }

        setLastScrollY(currentScrollY);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, location.pathname]);

  useEffect(() => {
    if (['/', '/checkout', '/menu', '/contact'].includes(location.pathname)) {
      setIsVisible(true);
    }
  }, [location.pathname]);

  return (
    <div className="container">
      <Navbar
        expand="lg"
        className={`header ${isVisible ? 'visible' : 'hidden'}`}
        fixed="top"
      >
        <Container>
          <Navbar.Brand onClick={() => navigate('/')} className="header-logo">
            <img src={logo} alt="Logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-center">
              <Nav.Link onClick={() => handleNavClick('/')} className="nav-item">Home</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('/menu')} className="nav-item">Menu</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('/order')} className="nav-item">Order</Nav.Link>
              <Nav.Link onClick={() => handleNavClick('/checkout')} className="nav-item">Checkout</Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <div className="top-buttons">
            <button className="btn btn-success carryout-btn" onClick={() => handleNavClick('/order')}>
              <FaUser className="icon" /> Order Now
            </button>

          </div>
        </Container>

        {/* Bottom buttons displayed only on smaller screens */}
        {location.pathname === '/' && (
          <div className="bottom-buttons">
            <div className="touch-button" onClick={() => handleNavClick('/order')}>
              <FaUser className="icon" /> Carryout
            </div>
            <div className="vertical-split" />
            <div className="touch-button" onClick={() => handleNavClick('/order')}>
              <FaCar className="icon" /> Delivery
            </div>
          </div>
        )}
      </Navbar>
    </div>
  );
};

export default Header;
