import React, { useState, useRef, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';
import CategoryModal from '../elements/CategoryModal';
import { BsArrowUp, BsArrowUpCircle } from 'react-icons/bs';

export default function Menu({ restaurant }) {
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [activeCategory, setActiveCategory] = useState(0);
  const categoryRefs = useRef([]);

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    setCategoryModalVisible(false);
    const categoryOffset = getCategoryOffset(categoryId);
    if (categoryOffset) {
      window.scrollTo({ top: categoryOffset, behavior: 'smooth' });
    }
  };

  const getCategoryOffset = (categoryId) => {
    const categoryElement = document.getElementById(`category-${categoryId}`);
    if (categoryElement) {
      const rect = categoryElement.getBoundingClientRect();
      return rect.top + window.scrollY - 200;
    }
    return 0;
  };

  // Detect active category on scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 250; // Adjust to trigger near category title
      restaurant?.Categories.forEach((category, index) => {
        const categoryElement = document.getElementById(`category-${index}`);
        if (categoryElement) {
          const offsetTop = categoryElement.offsetTop;
          const offsetHeight = categoryElement.offsetHeight;

          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
            setActiveCategory(index);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [restaurant]);

  return (
    <div>
      <div className="container popular-dishes-section my-5">
        <div className="text-center mb-5">
          <p className="text-pink fw-bold" style={{ marginTop: '110px' }}>Our Menu</p>
        </div>

        {restaurant?.Categories.map((category, index) => (
          <div key={category.id} className="mb-5" id={`category-${index}`}>
            <h3 className="text-center text-pink fw-bold mb-4">{category.name}</h3>
            <div className="row">
              {category.Items.map((item) => (
                <div className="col-md-4 col-6 mb-4" key={item.id}>
                  <div className="dish-card shadow-sm rounded">
                    <img
                      src={item.image_url || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScO99JkPvdfSyT3CbMrYsKBpRuXOACVR2cP9F6DBcP9B6nQ9oszX_18T-2oNv6Gyxwyhk&usqp=CAU'}
                      alt={item.name}
                      className="img-fluid rounded-top dish-image"
                    />
                    <div className="text-center">
                      <h5 className="fw-bold mb-1">{item.name}</h5>
                      <div className="d-flex align-items-center">
                        <FaStar className="text-pink" />
                        <span className="mx-1">4.5</span> {/* Static rating as a placeholder */}
                        <span className="fw-bold ms-auto">${item.price}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* Category Modal */}
        <CategoryModal show={categoryModalVisible} onClose={() => setCategoryModalVisible(false)}>
          <div className="category-modal-content">
            {restaurant?.Categories.map((category, index) => (
              <div
                key={category.id}
                className={`category-modal-item ${activeCategory === index ? 'active' : ''}`}
                onClick={() => handleCategoryClick(index)}
              >
                {category.name}
              </div>
            ))}
          </div>
        </CategoryModal>

        {/* Additional styling */}
        <style jsx>{`
          .category-button {
            position: sticky;
            bottom: 0;
            width: 100%;
            padding: 12px;
            font-size: 18px;
            color: white;
            background-color: #4caf50;
            border: none;
            border-radius: 10px 10px 0 0;
            box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
            text-align: center;
            cursor: pointer;
            transition: background-color 0.3s ease;
            }
            
        
              `}</style>
      </div>
              
                      {/* Button at the bottom */}
                      <button
                        onClick={() => setCategoryModalVisible(true)}
                        className="category-button"
                      >
                        {restaurant?.Categories[activeCategory]?.name || "Categories"}
                      {'      '}
                        <BsArrowUpCircle />
                      </button>
    </div>
  );
}
