import { useNavigate } from "react-router-dom";
import './css/Hero.css'
import hero from '../asset/Images/hero1.JPG'

export default function HeroSection() {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;

  return (
    <div className="container" style={{backgroundColor:'white', marginTop:'100px'}}>
      <div className="row align-items-center">
        <div className="col-lg-6 text-center text-md-start">
          <div className="banner-content">
            <h1 className="hero-title">BRING THE FLAVOR™</h1>
            <p className="hero-text">
              We know one flavor doesn't fit all, that's why we perfected 12 of them.
              Your next flavor obsession starts with just one click.
            </p>
            <button
              className="btn btn-outline-success explore-menu-button"
              onClick={() => navigate('/order')}
            >
              Order Now
            </button>
          </div>
        </div>
        <div className="col-lg-6">
          <img
            className="hero-background-image"
            src={hero}
            alt="Wings and Fries"
          />
        </div>
      </div>
    </div>
  );
}
