import { useNavigate } from 'react-router-dom';
import './css/Banner.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function BannerSection() {
  const navigate = useNavigate();

  const items = [
    {
      image: 'https://cdn.bfldr.com/NDQASMJ1/as/2k7ghjwnfhfbfnnr8thw5ppj/Boneless_Meal_Deal?auto=webp&format=png&width=400',
      title: 'BONELESS MEAL DEAL',
      description: 'Comes with 20 Boneless Wings in your choice of 4 flavors, with a large fry and 2 dips. (Feeds 2-3)',
    },
    {
      image: 'https://cdn.bfldr.com/NDQASMJ1/as/2k7ghjwnfhfbfnnr8thw5ppj/Boneless_Meal_Deal?auto=webp&format=png&width=400',
      title: 'CHICKEN SANDWICH COMBO',
      description: 'Comes with 20 Boneless Wings in your choice of 4 flavors, with a large fry and 2 dips. (Feeds 2-3)',
    },
    {
      image: 'https://cdn.bfldr.com/NDQASMJ1/as/2k7ghjwnfhfbfnnr8thw5ppj/Boneless_Meal_Deal?auto=webp&format=png&width=400',
      title: '10 PC CLASSIC COMBO',
      description: 'Comes with 20 Boneless Wings in your choice of 4 flavors, with a large fry and 2 dips. (Feeds 2-3)',
    },
  ];

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1199, min: 780 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 779, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  
  

  const handleOrderNow = () => {
    navigate('/order');
  };

  return (
    <div className="container my-5 text-center banner-section">
      <h2 className="fw-bold">FOR YOU</h2>
      <p className="text-muted">Curated by your flavor experts</p>
      <Carousel responsive={responsive}
        itemClass="carousel-item-padding" // Add this line for custom padding
        className="for-you-item-container">
        {items.map((item, index) => (
          <div key={index} className="item-card-1">
            <img src={item.image} alt="food" className="item-image-1" />
            <h3 className="item-title-1">{item.title}</h3>
            <p className="item-description-1">{item.description}</p>
            <button onClick={handleOrderNow} className="customize-button">
              Order Now
            </button>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
